import { Children, cloneElement, isValidElement } from 'react'

import { Box, HTMLChakraProps, forwardRef } from '@chakra-ui/react'

import { useStyles } from './useDropdown'

export const DropdownIcon = forwardRef<HTMLChakraProps<'span'>, 'span'>(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ className, children, ...props }, ref) => {
		const styles = useStyles()

		const child = Children.only(children)

		const clone = isValidElement(child)
			? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			  cloneElement<any>(child, {
					focusable: 'false',
					'aria-hidden': true,
					className: child.props.className
			  })
			: null

		return (
			<Box
				as="span"
				ref={ref}
				{...props}
				__css={styles.icon}
			>
				{clone}
			</Box>
		)
	}
)
DropdownIcon.displayName = 'DropdownIcon'
