import {
	UseBreakpointOptions,
	useBreakpointValue as useBreakpointValueChakraUI
} from '@chakra-ui/react'
import { isObject } from 'radash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBreakpointValueWithoutSSR = <T = any>(
	values: Partial<Record<string, T>> | Array<T | null>,
	arg?: UseBreakpointOptions | string
): T | undefined => {
	const options: UseBreakpointOptions = {
		ssr: false,
		fallback: isObject(arg) ? arg.fallback : arg
	}

	return useBreakpointValueChakraUI(values, options)
}

export const useDesktopBreakpoint = () => {
	return useBreakpointValueWithoutSSR({
		base: false,
		md: false,
		xl: true
	})
}

export const useTabletBreakpoint = () => {
	return useBreakpointValueWithoutSSR({
		base: false,
		md: true,
		xl: false
	})
}

export const useMobileBreakpoint = () => {
	return useBreakpointValueWithoutSSR({
		base: true,
		md: false,
		xl: false
	})
}
