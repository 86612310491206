import { OfferStatus } from '@wwt/entities/api/search-response'

export const showOfferConfigurationOfferStatuses: OfferStatus[] = [
	OfferStatus.AVAILABLE,
	// OfferStatus.RESERVATION_FAILED,
	OfferStatus.RESERVATION_CANCELLED,
	OfferStatus.PAYMENT_FAILED,
	OfferStatus.EXPIRED_DATES
]
export const showCancelButtonOfferStatuses: OfferStatus[] = [
	OfferStatus.RESERVED,
	// OfferStatus.TRIP_COMPLETED,
	OfferStatus.RESERVATION_PENDING,
	OfferStatus.PAYMENT_PENDING
]
export const showReserveButtonOfferStatuses: OfferStatus[] = [
	OfferStatus.AVAILABLE,
	// OfferStatus.TRIP_COMPLETED,
	// OfferStatus.RESERVATION_FAILED,
	OfferStatus.RESERVATION_CANCELLED,
	OfferStatus.RESERVED,
	OfferStatus.PAYMENT_FAILED
]
export const showPriceOfferStatuses: OfferStatus[] = [
	OfferStatus.AVAILABLE,
	OfferStatus.RESERVED,
	OfferStatus.RESERVATION_PENDING,
	OfferStatus.PAYMENT_PENDING,
	// OfferStatus.RESERVATION_FAILED,
	// OfferStatus.TRIP_COMPLETED,
	OfferStatus.RESERVATION_CANCELLED,
	OfferStatus.PAYMENT_FAILED
]
export const showLikeButtonOfferStatuses: OfferStatus[] = [
	OfferStatus.AVAILABLE,
	OfferStatus.RESERVED,
	OfferStatus.RESERVATION_PENDING,
	OfferStatus.PAYMENT_PENDING,
	// OfferStatus.RESERVATION_FAILED,
	OfferStatus.RESERVATION_CANCELLED,
	OfferStatus.PAYMENT_FAILED
]
