import { RefinementCtx, ZodIssueCode } from 'zod'

import { bookablePackagedPackagesValidator } from './validators/bookablePackagedValidators/bookablePackagedPackagesValidator.ts'
import { featureHasOnlyOneSelectedOption } from './validators/featureHasOnlyOneSelectedOption/featureHasOnlyOneSelectedOption.ts'
import { allFeaturesNamesValidator } from './validators/featuresNameValidator/allFeaturesNamesValidator.ts'
import { featureOptionNamesValidator } from './validators/featuresNameValidator/featureOptionNamesValidator.ts'
import { responseContentImagesValidator } from './validators/responseContentImagesValidator/responseContentImagesValidator.ts'

export const ChatMessageTextAndSearchInfoDtoValidation = () => {
	return false
}

export const ChatSearchInfoFilterItemOptionValidation = () => {
	return false
}

export const ChatSearchInfoFilterItemRangeParamsValidation = () => {
	return false
}

export const CountElementsDtoValidation = () => {
	return false
}

export const ReviewDetailedDtoValidation = () => {
	return false
}

export const ReviewDtoValidation = () => {
	return false
}

export const ReviewImageDtoValidation = () => {
	return false
}

export const ReviewsDtoValidation = () => {
	return false
}

export const NotificationClientListResponseDtoValidation = () => {
	return false
}

export const NotificationClientUpdateRequestDtoValidation = () => {
	return false
}

export const NotificationDtoValidation = () => {
	return false
}

export const NotificationStatusEnumValidation = () => {
	return false
}

export const NotificationActionRedirectToComparisonValidation = () => {
	return false
}

export const NotificationActionRedirectToReservationValidation = () => {
	return false
}

export const NotificationActionRedirectToRoomOfferWithOfferIdValidation =
	() => {
		return false
	}

export const NotificationActionRedirectToHomeValidation = () => {
	return false
}

export const NotificationActionStartTutorialValidation = () => {
	return false
}

export const NotificationActionOpenModalChatAssistantValidation = () => {
	return false
}

export const BaseNotificationLinkDtoValidation = () => {
	return false
}

export const NotificationActionEnumValidation = () => {
	return false
}

export const PageEnumValidation = () => {
	return false
}

export const ModalEnumValidation = () => {
	return false
}

export const BookingGuestsDtoValidation = () => {
	return false
}

export const GenderEnumValidation = () => {
	return false
}

export const PetDtoValidation = () => {
	return false
}

export const SocketErrorDtoValidation = () => {
	return false
}

export const SocketSuccessResponseValidation = () => {
	return false
}

export const ChatClientChatUpdateRequestValidation = () => {
	return false
}

export const ChatClientChatsRequestValidation = () => {
	return false
}

export const ChatClientChatsResponseValidation = () => {
	return false
}

export const ChatClientMessageCreateRequestValidation = () => {
	return false
}

export const ChatClientMessageCreateResponseValidation = () => {
	return false
}

export const ChatClientMessagesRequestValidation = () => {
	return false
}

export const ChatClientMessagesResponseValidation = () => {
	return false
}

export const GuestPetDtoValidation = () => {
	return false
}

export const GuestPetTypeEnumValidation = () => {
	return false
}

export const ChatFromEnumValidation = () => {
	return false
}

export const ChatSearchInfoDTOValidation = () => {
	return false
}

export const ChatTabEnumValidation = () => {
	return false
}

export const OfferOptionalDtoValidation = () => {
	return false
}

export const ChatDTOValidation = () => {
	return false
}

export const ChatMessageDTOValidation = () => {
	return false
}

export const SaveDislikeResponseDtoValidation = () => {
	return false
}

export const ReservationStatusDtoValidation = () => {
	return false
}

export const ReservationStatusEnumValidation = () => {
	return false
}

export const ReasonDtoValidation = () => {
	return false
}

export const OfferStatusEnumValidation = () => {
	return false
}

export const OfferValidationResponseDtoValidation = () => {
	return false
}

export const LikesResponseDtoValidation = () => {
	return false
}

export const GeolocationAddressValidation = () => {
	return false
}

export const DislikedOfferIdsDtoValidation = () => {
	return false
}
export const DislikedOfferIdsListDtoValidation = () => {
	return false
}

export const CreateReasonDtoValidation = () => {
	return false
}

export const ChangelogOfferDtoValidation = () => {
	return false
}

export const ChangelogOffersMapValidation = () => {
	return false
}

export const CreateSetRequestValidation = () => {
	return false
}

export const SetModeEnumValidation = () => {
	return false
}

export const SetResponseDtoValidation = () => {
	return false
}

export const SetsResponseDtoValidation = () => {
	return false
}

export const OfferResponseDtoValidation = () => {
	return false
}

export const OfferResponseMetaDataDtoValidation = () => {
	return false
}

export const OfferResponseStaticDataDtoValidation = () => {
	return false
}

export const PackagePriceDtoValidation = () => {
	return false
}

export const ReservationCancelledRequestDtoValidation = () => {
	return false
}

export const ReservationDtoValidation = () => {
	return false
}

export const TaxDtoValidation = () => {
	return false
}

export const UpdateSetRequestDtoValidation = () => {
	return false
}

export const SaveOfferRequestDtoValidation = () => {
	return false
}

export const SaveOfferStaticDataDtoValidation = () => {
	return false
}

// export const AbstractFilterDtoValidation = () => {
// 	return false
// }

export const ActiveFilterDtoValidation = () => {
	return false
}

export const AuthenticationRequestDtoValidation = () => {
	return false
}

export const BillingAddressValidation = () => {
	return false
}

export const BlogDtoValidation = () => {
	return false
}

export const BookableNotPackagedFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const BookableNotPackagedFeatureOptionDtoValidation = () => {
	return false
}

export const BookablePackageDtoValidation = () => {
	return false
}

export const BookablePackagedFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const BookablePackagedFeatureOptionDtoValidation = () => {
	return false
}

export const CancelReservationDtoValidation = () => {
	return false
}

export const CheckInFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const CheckInFeatureOptionDtoValidation = () => {
	return false
}

export const ContactDataValidation = () => {
	return false
}

export const CreateReservationDtoValidation = () => {
	return false
}

export const DateMatrixDtoValidation = () => {
	return false
}

export const DeviceValidation = () => {
	return false
}

export const FeatureGroupValidation = () => {
	return false
}

// export const FilterDtoValidation = () => {
// 	return false
// }
//
// export const FilterResponseDtoValidation = () => {
// 	return false
// }

export const FlagSearchDtoValidation = () => {
	return false
}

export const GeolocationDtoValidation = () => {
	return false
}

export const GeolocationValidation = () => {
	return false
}

export const GuestDetailsDtoValidation = () => {
	return false
}

export const GuestDtoValidation = () => {
	return false
}

export const GuestQuantityDtoValidation = () => {
	return false
}

export const HotelDynamicDataDtoValidation = () => {
	return false
}

export const HotelSearchDtoValidation = () => {
	return false
}

export const HotelStaticDataDtoValidation = () => {
	return false
}

export const ImageDtoValidation = () => {
	return false
}

export const ImagePropertiesDtoValidation = () => {
	return false
}

export const IncludedFeatureDtoValidation = () => {
	return false
}

export const IncludedFeatureOptionDtoValidation = () => {
	return false
}

export const InfoProvidedValidation = () => {
	return false
}

export const IntegrationSearchDtoValidation = () => {
	return false
}

export const JwtResponseDtoValidation = () => {
	return false
}

export const LikeReactionDtoValidation = () => {
	return false
}

export const MetadataValidation = () => {
	return false
}

export const OfferCreateResponseDtoValidation = () => {
	return false
}

export const OfferDynamicDataDtoValidation = () => {
	return false
}

export const OfferMetaDataDtoValidation = () => {
	return false
}

export const OffersResponseDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const offersWithErrors = [
		...responseContentImagesValidator(data),
		...allFeaturesNamesValidator(data)
	]

	offersWithErrors.forEach(offerWithError => {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			...offerWithError
		})
	})
}

export const OptionFilterDtoValidation = () => {
	return false
}

export const SearchResponseDtoValidation = () => {
	return false
}

export const PaginationDtoValidation = () => {
	return false
}

export const PaymentCardValidation = () => {
	return false
}

export const PaymentDataValidation = () => {
	return false
}

export const PointDtoValidation = () => {
	return false
}

export const RangeParamsDtoValidation = () => {
	return false
}

export const ResponseMessageDtoValidation = () => {
	return false
}

export const ResponseErrorDtoValidation = () => {
	return false
}

export const RoomDynamicDataDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const errorsAfterValidation = bookablePackagedPackagesValidator(
		data.bookablePackagedFeatures
	)

	errorsAfterValidation.forEach(error => {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			path: error.path ?? [],
			...error
		})
	})

	const checkInDate = new Date(data.checkIn)
	const checkOutDate = new Date(data.checkOut)

	if (checkOutDate.getTime() <= checkInDate.getTime()) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: 'checkOut date must be later than checkIn date.'
		})
	}
}

export const RoomFaqDtoValidation = () => {
	return false
}

export const RoomReviewAndFaqDtoValidation = () => {
	return false
}

export const RoomReviewDtoValidation = () => {
	return false
}

export const RoomSearchDtoValidation = () => {
	return false
}

export const RoomStaticDataDtoValidation = () => {
	return false
}

export const SearchOffersDtoValidation = () => {
	return false
}

export const SortDtoValidation = () => {
	return false
}

export const SortResponseDtoValidation = () => {
	return false
}

export const StayDtoValidation = () => {
	return false
}

export const StayPeriodDtoValidation = () => {
	return false
}

export const ThreeDsDataValidation = () => {
	return false
}

// export const TypeableFilterDtoValidation = () => {
// 	return false
// }
