import { useEffect, useRef } from 'react'

import {
	Button,
	ButtonProps,
	Icon,
	Modal,
	ModalContent,
	ModalOverlay,
	Text,
	UseControllableStateProps,
	forwardRef,
	omitThemingProps,
	useControllableState,
	useDisclosure,
	useMergeRefs,
	useMultiStyleConfig
} from '@chakra-ui/react'
import { useDesktopBreakpoint } from '@wwt/custom/chakra-ui'
import { wwtMemo } from '@wwt/custom/wwt'
import { UseSelectProps, useSelect } from 'downshift'

import ArrowDownIcon from '@wwt/shared/assets/icons/outline/arrow-down.svg'

import {
	Dropdown,
	DropdownAnchor,
	DropdownItem,
	DropdownList
} from '../Dropdown'
import { SelectModal } from './SelectModal'

export interface SelectProps<T>
	extends Omit<ButtonProps, 'value' | 'defaultValue' | 'onChange'>,
		Pick<
			UseSelectProps<T>,
			'items' | 'itemToString' | 'itemToKey' | 'isItemDisabled'
		>,
		UseControllableStateProps<T | null> {
	icon?: React.ReactElement
	prefix?: string
	placeholder?: string
	render?: (item: T, isSelected: boolean) => React.ReactNode
}

// TODO: decompose this select to custom dropdown component
// so here will be only select logic without markup
/**
 * @deprecated Use `Select` from `@/shared/ui/select` instead
 */
const SelectInner = <T,>(
	{
		icon,
		prefix,
		placeholder,
		items,
		itemToString,
		itemToKey,
		render,
		value,
		defaultValue,
		onChange,
		shouldUpdate,
		isDisabled,
		isLoading,
		isItemDisabled = () => false,
		...props
	}: SelectProps<T>,
	ref: React.ForwardedRef<HTMLButtonElement>
) => {
	const [selectedItem, setSelectedItem] = useControllableState({
		defaultValue: defaultValue ?? null,
		value,
		onChange,
		shouldUpdate
	})

	const divProps = omitThemingProps(props)

	const buttonRef = useRef<HTMLButtonElement>(null)

	const refs = useMergeRefs(buttonRef, ref)

	const styles = useMultiStyleConfig('WwtSelect', props)

	const isDesktop = useDesktopBreakpoint()

	const {
		isOpen: isModalOpen,
		onClose: onModalClose,
		onOpen: onModalOpen
	} = useDisclosure()

	useEffect(() => {
		if (isDesktop) {
			onModalClose()
		}
	}, [isDesktop, onModalClose])

	const stateReducer: UseSelectProps<T>['stateReducer'] = (
		_state,
		actionAndChanges
	) => {
		const { type, changes } = actionAndChanges
		// this prevents the menu from being closed when the user opens a modal with 'Enter' or mouse
		switch (type) {
			case useSelect.stateChangeTypes.ToggleButtonClick:
				// open modal
				if (!isDesktop) {
					onModalOpen()
				}

				return changes // business as usual.
			default:
				return changes // otherwise business as usual.
		}
	}

	const {
		isOpen,
		closeMenu,
		selectItem,
		getToggleButtonProps,
		getMenuProps,
		getItemProps
	} = useSelect({
		items,
		itemToString,
		itemToKey,
		selectedItem,
		onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
			setSelectedItem(newSelectedItem),
		stateReducer,
		isItemDisabled
	})

	return (
		<Dropdown
			isOpen={isOpen}
			onClose={closeMenu}
		>
			<DropdownAnchor>
				<Button
					data-wwt-id="select__main--button"
					isActive={isOpen}
					iconSpacing="auto"
					leftIcon={icon}
					rightIcon={
						<Icon
							as={ArrowDownIcon}
							// build doesn't pass without it
							// eslint-disable-next-line dot-notation
							__css={styles['icon']}
							transform={isOpen ? 'rotate(180deg)' : ''}
						/>
					}
					// build doesn't pass without it
					// eslint-disable-next-line dot-notation
					__css={styles['field']}
					isDisabled={isDisabled}
					isLoading={isLoading}
					{...divProps}
					{...getToggleButtonProps({
						ref: refs,
						disabled: isDisabled || isLoading
					})}
				>
					<Text
						as="span"
						noOfLines={1}
					>
						{prefix} {selectedItem ? itemToString?.(selectedItem) : placeholder}
					</Text>
				</Button>
			</DropdownAnchor>

			{!isDesktop ? (
				<Modal
					isOpen={isModalOpen}
					onClose={onModalClose}
				>
					<ModalOverlay />
					<ModalContent {...getMenuProps({}, { suppressRefError: true })}>
						<SelectModal
							onClose={onModalClose}
							items={items}
							itemToKey={itemToKey}
							itemToString={itemToString}
							render={render}
							placeholder={placeholder}
							prefix={prefix}
							selectedItem={selectedItem}
							onSelect={selectItem}
							getItemProps={getItemProps}
							getMenuProps={getMenuProps}
						/>
					</ModalContent>
				</Modal>
			) : (
				<DropdownList
					rootProps={{
						zIndex: 15
					}}
					{...getMenuProps({}, { suppressRefError: true })}
				>
					{items.map((item, index) => {
						const props = getItemProps({ item, index })

						return (
							<DropdownItem
								key={itemToKey?.(item) ?? itemToString?.(item) ?? index}
								{...props}
							>
								{itemToString?.(item)}
								{render?.(item, props['aria-selected'])}
							</DropdownItem>
						)
					})}
				</DropdownList>
			)}
		</Dropdown>
	)
}

export const Select = wwtMemo(forwardRef(SelectInner)) as <T>(
	// eslint-disable-next-line no-use-before-define
	props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectInner>
